<template>
	<div class="suppierDetail">
		<el-card class="box-card">
            <div slot="header" class="clearfix">
                基本信息
            </div>
            <div class="content">
                <div class="filter-container" v-if="$route.query.accountType == 3">
                    <div class="filter-item">
                        <label class="label">真实姓名：</label>
                        {{name}}
                    </div>
                    <div class="filter-item">
                        <label class="label">手机号：</label>
                        {{phone}}
                    </div>
                    <div class="filter-item">
                        <label class="label">身份证号码：</label>
                        {{identityNo}}
                    </div>
                </div>

                <div class="filter-container" v-if="$route.query.accountType == 2">
                    <div class="filter-item">
                        <label class="label">公司名称：</label>
                        {{companyName}}
                    </div>
                    <div class="filter-item">
                        <label class="label">法定代表人：</label>
                        {{name}}
                    </div>
                    <div class="filter-item">
                        <label class="label">身份证号码：</label>
                        {{identityNo}}
                    </div>
                </div>
                <div class="filter-container" v-if="$route.query.accountType == 2">
                    <div class="filter-item">
                        <label class="label">手机号：</label>
                        {{phone}}
                    </div>
                    <div class="filter-item">
                        <label class="label">营业执照号：</label>
                        {{businessLicense}}
                    </div>
                    <div class="filter-item">
                        <label class="label">组织机构代码：</label>
                        {{organizationCode}}
                    </div>
                </div>
                <div class="filter-container" v-if="$route.query.accountType == 2">
                    <div class="filter-item">
                        <label class="label">税务登记号码：</label>
                        {{taxRegister}}
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="box-card" style="margin-top:30px;">
            <div slot="header" class="clearfix">
                账户信息
            </div>
            <div class="content">
                <div class="filter-container">
                    <div class="filter-item">
                        <label class="label">开户行名称：</label>
                        {{parentBankName}}
                    </div>
                    <div class="filter-item" style="width:400px;">
                        <label class="label">开户支行：</label>
                        {{bankName}}
                    </div>
                    <div class="filter-item">
                        <label class="label">银行卡号：</label>
                        {{cardNo}}
                    </div>
                </div>
            </div>
        </el-card>
	</div>
</template>

<script>
import {
    getDetail
} from '@/api/finance'
export default {
	components: {
        
	},
	data () {
		return {
            bankName: null,     //支行名称
            parentBankName:'',  //银行名称
            identityNo: "",     //身份证号
            name: "",   //名称
            phone: "",  //手机号
            cardNo:'' , //银行卡号
            taxRegister:'', //税务登记证
            organizationCode:'', //组织机构代码
            businessLicense:'', //营业执照号
            companyName:'', //企业名称
		}
	},
	created () {
        this.getDetail();
	},
	computed: {
		
	},
	methods: {
		// 获取详情
		async getDetail () {
			try {
				this.loading = true
				let result = await getDetail({id:this.$route.query.id});
                if (result.success) {
                    if (this.$route.query.accountType == 3) {
                        this.bankName = result.data.bankName;
                        this.parentBankName = result.data.parentBankName;
                        this.identityNo = result.data.identityNo;
                        this.name = result.data.name;
                        this.phone = result.data.phone;
                        this.cardNo = result.data.cardNo;
                    }else{
                        this.bankName = result.data.bankName;
                        this.parentBankName = result.data.parentBankName;
                        this.identityNo = result.data.legalIds;
                        this.name = result.data.legalName;
                        this.phone = result.data.legalPhone;
                        this.cardNo = result.data.accountNo;
                        this.taxRegister = result.data.taxRegister;
                        this.businessLicense = result.data.businessLicense;
                        this.organizationCode = result.data.organizationCode;
                        this.companyName = result.data.companyName;
                    }
                    

                    if(result.data.userState == 1){
                        this.$message({
                            showClose: true,
                            duration:0,
                            type: 'success',
                            dangerouslyUseHTMLString: true,
                            message: '<h4>审核通过！</h4><span>你所提交的信息已经审核通过，请及时跟进申请状况。如有问题，请联系审核人员或者拨打客服电话400-400-400。</span>'
                        });
                    }else if(result.data.userState == 7){
                        this.$message({
                            showClose: true,
                            duration:0,
                            type: 'warning',
                            dangerouslyUseHTMLString: true,
                            message: '<h3>您的认证正在审核，预计7个工作日审核完毕</h3>'
                        });
                    }else if(result.data.userState == 3){
                        this.$message({
                            showClose: true,
                            duration:0,
                            type: 'error',
                            dangerouslyUseHTMLString: true,
                            message: '<h3>审核失败！</h3><span>失败原因：4522555。如有问题，请联系审核人员或者拨打客服电话400-400-400。</span>'
                        });
                    }
                }else{
                    this.$message({
                        showClose: true,
                        type: 'error',
                        message: result.alertMsg
                    });
                }
            } finally {
				this.loading = false
			}
        },
	}
}
</script>
<style lang="less" scoped>
    .content{
        .filter-item{
            width: 300px;
            .label{
                width: 100px;
            }
        }
    }
</style>
